import React from 'react';
import { createRoot } from 'react-dom/client';
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';

import App from './App';

LicenseInfo.setLicenseKey(
  '11ba094a732c1cc3a040c3617616212bTz02OTY2NixFPTE3MTk1ODM5Mjc1ODYsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
