const STRATEGY_DASHBOARD_CLIENT_REVENUE_REPORT_PARAMS = {
  heading: 'Strategy Dashboard',
  subheading: 'Monthly Revenue',
  views: {
    urls: ['https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ClientRevenueDashDraft-V1/Summary?:iid=1', 'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ClientRevenueDashDraft-V1/SummarybySource?:iid=1', 'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ClientRevenueDashDraft-V1/SummaryCodeGrouping?:iid=1'],
    labels: ['Summary', 'Summary by Source', 'Summary Code Grouping'],
  },
};

const ACQUISITION_PERFORMANCE_REPORT_PARAMS = {
  heading: 'Acquisition',
  subheading: 'Performance',
  views: {
    urls: ['https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTAcquisition2022ElectionResults/AAIAcquisitionResults?:iid=1', 'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTAcquisition2022ElectionResults/AAIAcquisitionRevenueInvestment?:iid=1', 'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTAcquisition2022ElectionResults/PathtoBreakevenbyClient?:iid=1'],
    labels: ['Acquisition Results', 'Acquisition Revenue/Investment', 'Path to Breakeven'],
  },
};

const ACQUISITION_INTERNAL_PERFORMANCE_REPORT_PARAMS = {
  heading: 'Acquisition',
  subheading: 'Internal Performance',
  views: {
    urls: ['https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/AdAIPerformanceAnalysis/ROIAnalysis?:iid=1', 'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/AdAIPerformanceAnalysis/BreakevenDate?:iid=1'],
    labels: ['ROI Analysis', 'Breakeven Date'],
  },
};

const REACTIVATION_PERFORMANCE_REPORT_PARAMS = {
  heading: 'Reactivation',
  subheading: 'Performance',
  views: {
    urls: ['https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTReactivation2022ElectionResultsDashboard/AAIReactivationResults?:iid=2', 'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTReactivation2022ElectionResultsDashboard/AAIReactivationRevenueInvestment?:iid=2'],
    labels: ['Reactivation Results', 'Reactivation Revenue/Investment'],
  },
};

const COOP_DASHBOARDS_POLITICAL_MARKETING_REPORT_PARAMS = {
  heading: 'Co-op Dashboards',
  subheading: 'Political Marketing/AAI Trends',
  views: {
    urls: ['https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTPoliticalMarketing/PoliticalMarketingTrends?:iid=5', 'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DRAFTPoliticalMarketing/AAI-PoliticalMarketingTrends?:iid=6'],
    labels: ['Political Marketing Trends', 'AAI Political Marketing Trends'],
  },
};

// for the dashboards landing page

const ACQUISITION_LINKS = [
  {
    name: 'Performance',
    url: '/reporting/acquisition_performance',
  },
  {
    name: 'Inventory',
    url: '/reporting/acquisition_inventory',
  },
  {
    name: 'Delivery Metrics',
    url: '/reporting/acquisition_delivery_metrics',
  },
  {
    name: 'Internal Performance',
    url: '/reporting/acquisition_internal_performance',
  },
];

const REACTIVATION_LINKS = [
  {
    name: 'Performance',
    url: '/reporting/reactivation_performance',
  },
  {
    name: 'Inventory',
    url: '/reporting/reactivation_inventory',
  },
];

const COOP_DASHBOARDS_LINKS = [
  {
    name: 'Client Revenue',
    url: '/reporting/coop_dashboards_client_revenue',
  },
  {
    name: 'Political Marketing/AAI Trends',
    url: '/reporting/coop_dashboards_political_marketing_aai',
  },
];

const STRATEGY_DASHBOARDS_LINKS = [
  {
    name: 'Deliverability',
    url: '/reporting/strategy_deliverability',
  },
  {
    name: 'Champy Ads Results',
    url: '/reporting/strategy_champy_ads',
  },
  {
    name: 'Monthly Revenue',
    url: '/reporting/strategy_client_revenue',
  },
];

export {
  STRATEGY_DASHBOARD_CLIENT_REVENUE_REPORT_PARAMS, ACQUISITION_PERFORMANCE_REPORT_PARAMS,
  ACQUISITION_INTERNAL_PERFORMANCE_REPORT_PARAMS,
  REACTIVATION_PERFORMANCE_REPORT_PARAMS,
  COOP_DASHBOARDS_POLITICAL_MARKETING_REPORT_PARAMS,
  ACQUISITION_LINKS,
  REACTIVATION_LINKS,
  COOP_DASHBOARDS_LINKS,
  STRATEGY_DASHBOARDS_LINKS,
};
