import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { createTransactionRows } from '../../utils/tableHelpers';
import APIWrapper from '../../utils/graphqlwrapper';
import { transactionColumns } from '../../utils/schemaInfo';
import logger from '../../utils/logger';
import AppContext from '../../context';
import AdvancedSearchV2 from '../AdvancedSearchV2';
import ServerTable from '../ServerTable';
import AdvancedSearchChip from '../AdvancedSearchChip';

const initColumnVisibilityModel = {};

function AllTransactions() {
  const { client } = useParams();
  const { setShouldLogOut } = useContext(AppContext);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initColumnVisibilityModel,
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageState, setPageState] = useState({
    isLoading: true,
    page: 0, // mui pages are 0 indexed
    pageSize: 50,
    data: [],
    total: 0,
  });
  const [snackMessage, setSnackMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    document.title = 'MissionPortal | Transactions';
  }, []);

  const saveExclusionUpdates = async (e, changedRows, excluded) => {
    /**
     * saveExclusionUpdates - save changes to database for selected rows
     * @param {Object} e event object for button click - to access innerText
     * @param {Object} changedRows Selected rows to be excluded/unexcluded in database
     * @param {Boolean} excluded whether or not we are excluding, or unexcluding the selected rows
     * @returns
     */
    const updatedTrans = [];
    let exclusionMessage = '';
    const errorsReturned = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const row of changedRows) {
      const rowCopy = row;
      ['createdAt', 'updatedAt', 'code_source'].forEach((item) => delete rowCopy[item]);
      if (excluded) {
        rowCopy.exclude_flag = true;
      } else {
        rowCopy.exclude_flag = false;
      }
      rowCopy.exclusion_updated_dt = new Date().toISOString();
      // eslint-disable-next-line no-await-in-loop
      await APIWrapper.queryApi(
        { mutation: 'updateTransactions' },
        setShouldLogOut,
        {
          input: rowCopy,
        },
      )
        .then((resp) => {
          updatedTrans.push(resp.data[0][0].foreign_transaction_key);
        })
        .catch((err) => {
          errorsReturned.push({ err, row: rowCopy });
          logger.error(err);
        });
    }
    if (errorsReturned.length > 0) {
      exclusionMessage = 'There was an error excluding the following transaction IDs: ';
      for (let i = 0; i < errorsReturned.length; i += 1) {
        const current = errorsReturned[i];
        if (i + 1 === errorsReturned.length) {
          exclusionMessage += `and ${current.row.foreign_transaction_key}.`;
        } else {
          exclusionMessage += `${current.row.foreign_transaction_key}, `;
        }
      }
    }
    if (updatedTrans.length > 0) {
      exclusionMessage += `Successfully updated exclusion status for ${
        updatedTrans.length
      } transactions: ${updatedTrans.join(', ')}`;
    }
    setSnackMessage(exclusionMessage);
    setOpenSnackbar(true);
  };

  // used for Snackbar
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setSnackMessage('');
  };

  // niceley formatted query for our API
  const [advancedSearchQuery, setAdvancedSearchQuery] = useState({
    client_id: {
      eq: client,
    },
  });

  // used to determine if user has executed a search and it's okay to show table
  const [initSearchExecuted, setInitSearchExecuted] = useState(false);

  // used for chips
  const [userCriteria, setUserCriteria] = useState([]);

  // search criteria user has entered in the advanced search
  const [currUserSearch, setCurrUserSearch] = useState(null);

  const deleteChip = (parentIndex) => {
    setCurrUserSearch((prevState) => {
      const newState = [...prevState];
      newState.splice(parentIndex, 1);
      return newState;
    });
  };

  const defaultSort = {
    field: 'transaction_dt',
    direction: 'desc',
  };

  const [advancedSearchSort, setAdvancedSearchSort] = useState(defaultSort);

  // default/locked criteria the user can't delete - search must be scoped ot current client
  const lockedCriteria = [
    {
      col: 'client_id',
      op: 'eq',
      criteria: client,
    },
  ];

  const getDefaultColumns = () => transactionColumns;
  const isRowSelectable = (params) => params.row.id !== null; // all rows selectable

  return (
    <>
      <div className="header_table_button_container">
        <div className="button_container-transactions_2">
          <Button
            variant="contained"
            onClick={(e) => saveExclusionUpdates(e, selectedRows, true)}
            className="toggle-search-type"
          >
            EXCLUDE
          </Button>
          <Button
            variant="outlined"
            onClick={(e) => saveExclusionUpdates(e, selectedRows, false)}
            className="toggle-search-type"
          >
            UNEXCLUDE
          </Button>
        </div>
        <AdvancedSearchV2
          currUserSearch={currUserSearch}
          setCurrUserSearch={setCurrUserSearch}
          searchSetter={setAdvancedSearchQuery}
          sortSetter={setAdvancedSearchSort}
          lockedCriteria={lockedCriteria}
          defaultSort={defaultSort}
          columns={transactionColumns}
          lockedColumns={['client_id']}
          setUserChips={setUserCriteria}
          initOpenState
          setInitSearchExecuted={setInitSearchExecuted}
        />
      </div>
      <AdvancedSearchChip
        columns={transactionColumns}
        lockedCriteria={lockedCriteria}
        userCriteria={userCriteria}
        setUserCriteria={setUserCriteria}
        onDelete={(index) => { deleteChip(index); }}
      />
      <div style={{ flexGrow: 1 }}>
        {client && initSearchExecuted && (
          <ServerTable
            pageState={pageState}
            updatePageState={setPageState}
            columns={transactionColumns}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
            filters={advancedSearchQuery}
            sortModel={advancedSearchSort}
            excludeFields={['client_id']}
            queryName="searchTransactions"
            tablePrefix="transactions-adv"
            checkboxSelection
            setSelectedRows={setSelectedRows}
            createRows={createTransactionRows}
            getDefaultColumns={getDefaultColumns}
            isRowSelectable={isRowSelectable}
          />
        )}
        {client && !initSearchExecuted && (
          <h2 style={{ display: 'flex', justifyContent: 'center' }}>Please enter in your search criteria</h2>
        )}
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={15000}
        onClose={handleClose}
        message={snackMessage}
      />
    </>
  );
}

export default AllTransactions;
