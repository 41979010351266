import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
// import { useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// import { API } from 'aws-amplify';

// import logger from '../utils/logger';
import TabPanel from '../components/TabPanel';

function ReportingDashboard(props) {
  // isAuth, userEmail,
  const {
    resc, token,
  } = props;
  const { heading, subheading, views } = resc;
  const { urls, labels } = views;

  const [value, setValue] = useState(0);
  const [tableauUrl, setTableauUrl] = useState(urls[0]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTableauUrl(urls[newValue]);
  };

  useEffect(() => {
    document.title = 'MissionPortal | Reporting';
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h2>
          Dashboards and Reporting &gt;
          {' '}
          {heading}
          {' '}
          &gt;
          {' '}
          {subheading}
        </h2>
      </header>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
        >
          {labels.map((label) => (<Tab label={label} key={label} />))}
        </Tabs>
      </Box>
      <div>
        <TabPanel value={value} index={value}>
          <tableau-viz
            id="tableau-viz"
          // This is the link to the reporting view, this link comes from our Tableau Cloud
            src={tableauUrl}
            width="100%"
            height="840"
            hide-tabs
            token={token}
            toolbar="top"
          />
        </TabPanel>
      </div>
    </div>
  );
}

export default ReportingDashboard;

TabPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

ReportingDashboard.propTypes = {
  resc: PropTypes.oneOfType([PropTypes.object]).isRequired,
  token: PropTypes.string,
};
