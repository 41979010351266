import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Reporting from './Reporting';
import theme from '../components/themes/Theme';

import logger from '../utils/logger';
import TabPanel from '../components/TabPanel';
import { logReactErrBoundaryError } from '../utils';
import FallbackOnError from '../components/FallbackOnError';
import Transactions from '../components/client/AllTransactions';
import TransactionsExclusions from './TransactionsExclusions';

function TransactionsContainer(props) {
  const { authenticated, token } = props;
  const [tab, setTab] = useState(0);
  const [currSubTab, setCurrSubTab] = useState(0);
  const param = useParams();
  const { client } = param;

  const SUM_BY_SOURCE_PARAMS = [
    { label: 'Sum by Source (Channels)', url: 'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ClientRevenueDashDraft-V1/SumBySourceChannels?:iid=1' },
    { label: 'Sum by Source (Codes)', url: 'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ClientRevenueDashDraft-V1/SumBySourceCodes?:iid=1' },
    { label: 'Bundling', url: 'https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ClientRevenueDashDraft-V1/Bundling?:iid=1' },
  ];

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleReportChange = (event, newValue) => {
    setCurrSubTab(newValue);
  };

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('details', details);
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <div>
        <header className="App-header">
          <h2>Transactions</h2>
        </header>
        <Box sx={{ width: '100%' }}>
          <>
            {' '}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleChange}>
                <Tab label="Sum by Source" />
                <Tab label="Import exclusion file" />
                <Tab label="View all transactions" />
              </Tabs>
            </Box>
            <>
              <TabPanel label="Sum by Source" value={tab} index={0}>
                <>
                  <h2>Reports</h2>
                  <div>
                    <Tabs
                      onChange={(e, val) => handleReportChange(e, val)}
                      value={currSubTab}
                      centered
                      sx={{
                        '.Mui-selected': {
                          color: theme.palette.info.main,
                        },
                      }}
                      TabIndicatorProps={{
                        style: {
                          background: theme.palette.info.main,
                        },
                      }}
                      key="innerTabs"
                    >
                      {SUM_BY_SOURCE_PARAMS.map((el, idx) => {
                        const k = `${el.label}-${idx}`;
                        return (
                          <Tab label={el.label} value={idx} key={k} />
                        );
                      })}
                    </Tabs>
                    <TabPanel
                      label={SUM_BY_SOURCE_PARAMS[currSubTab]}
                      value={currSubTab}
                      index={currSubTab}
                      customSX={{
                        backgroundColor: theme.palette.background.default,
                      }}
                      key={currSubTab}
                    >
                      <Reporting
                        isAuth={authenticated}
                        url={SUM_BY_SOURCE_PARAMS[currSubTab].url}
                        token={token}
                        heading={SUM_BY_SOURCE_PARAMS[currSubTab].label}
                        subheading=""
                        hideBreadcrumb
                      />
                    </TabPanel>
                  </div>
                </>
              </TabPanel>
              <TabPanel label="Import exclusion file" value={tab} index={1}>
                <TransactionsExclusions client={client} />
              </TabPanel>
              <TabPanel label="View all transactions" value={tab} index={2}>
                <Transactions />
              </TabPanel>
            </>
          </>
        </Box>
      </div>
    </ErrorBoundary>
  );
}

export default TransactionsContainer;

TransactionsContainer.propTypes = {
  token: PropTypes.string,
  authenticated: PropTypes.bool,
};
