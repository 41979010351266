/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, TextField } from '@mui/material';
import { Add } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import moment from 'moment';
import APIWrapper from '../utils/graphqlwrapper';
import { alphabetizeCols } from '../utils/tableHelpers';
import logger from '../utils/logger';
import {
  logReactErrBoundaryError, updateTablePreferenceLocalStorage, getLocalStorageTablePref, tableColumnPreferenceLoader,
} from '../utils';

import ServerTable from './ServerTable';
import FallbackOnError from './FallbackOnError';

const stringOps = [
  'eq', // =
  'ne', // !=
  'exists',
  'wildcard', // we're going to use this to mimic contains - note that it doesn't respect word order in phrase
];

const boolOps = [
  'eq',
  'ne',
];

// float or int
const numberOps = [
  'eq',
  'ne',
  'gt', // >
  'gte', // >=
  'lt', // <
  'lte', // <=
];

const opLabels = {
  exists: 'exists',
  eq: '=',
  gt: '>',
  gte: '≥',
  lt: '<',
  lte: '≤',
  ne: '≠',
  wildcard: 'contains',
};

// eventually should add between
const awsdtOps = [
  'gte',
  'lte',
];

const dtLabels = {
  gte: 'after',
  lte: 'before',
};

// reformats col data in obj instead of list so it's easier to check info about a col
function reformatCols(columns) {
  const reformattedCols = {};
  columns.forEach((col) => {
    reformattedCols[col.field] = {
      headerName: col.headerName,
      format: col.format,
    };
  });
  return reformattedCols;
}

function AdvancedSearch(props) {
  const {
    sortModel = {},
    columns = [],
    columnVisibilityModel = {},
    setColumnVisibilityModel,
    pageState,
    updatePageState,
    defaultFilter = [], // filter to start with (cannot be changed by user)
    excludeFields = [], // fields that user should not be able to change
    queryName,
    checkboxSelection,
    setSelectedRows,
    createRows,
    setOrderedColumns,
    handleEditClick,
    handleDeleteClick,
    tablePrefix,
    pinnedColumns,
    getDefaultColumns,
  } = props;
  const defaultInputFields = [
    {
      field: '', // user selected field to filter ex: client_id, revenue, transaction_id
      operation: '', // user selected operation ex: eq, ne, exist
      constraint: '', // user selected - right hand of operation
      constraintFieldType: '', // type of field the constraint field should be based on the operation/constraint
      operations: [], // possible operations for the selected field
      constraints: [], // possible values of constraints, if applicable - ex: if bool operation constraints = [true, false]
    },
  ];

  // makes sure the actions col we add to edit a col doesn't show up as something we can filter by
  const currCols = columns.filter((col) => !excludeFields.includes(col.field));

  const [inputFields, setInputFields] = useState(defaultInputFields);
  const [queryFilters, setQueryFilters] = useState({});

  // fields in dropdown should be alphabetically ordered so it's easy for users to find their desired col
  let alphabetizedCols = alphabetizeCols([...currCols], 'headerName');

  alphabetizedCols = alphabetizedCols.filter((col) => col.type !== 'actions');

  // list -> obj so easier to get info about a given col
  const reformattedCols = reformatCols(currCols);

  // update constraints baased on new format - if the current constraint is valid, try to leave it
  const updateConstraintTypes = (currformat, values, index, targetVal) => {
    const newValues = values;
    let constraintFieldType = 'text';
    let constraints = null;
    let constraintValue = null;
    if (currformat === 'Float' || currformat === 'Int') {
      try {
        const valid = !/^\s*$/.test(constraintValue) && !Number.isNaN(constraintValue);
        constraintValue = valid ? constraintValue : '';
      } catch (err) {
        logger.error(err);
        constraintValue = '';
      }
      constraintFieldType = 'number';
    } else if (currformat === 'String') {
      constraintFieldType = 'text';
    } else if (currformat === 'Boolean') {
      constraintFieldType = 'select';
      constraints = ['true', 'false'];

      // initially set to true
      if (!(values[index].constraint === 'true' || values[index].constraint === 'false')) {
        constraintValue = 'true';
      }
    } else if (currformat.constructor === Array) {
      constraintFieldType = 'select';
      constraints = currformat;
      constraintValue = '';
    } else if (currformat === 'AWSDateTime') {
      constraintFieldType = 'awsdatetime';
      constraintValue = new Date(Date.now()).toISOString();
    }

    if (targetVal === 'exists') {
      constraintFieldType = 'select';
      constraints = ['true', 'false'];

      if (!(values[index].constraint === 'true' || values[index].constraint === 'false')) {
        constraintValue = 'true';
      }
    }

    newValues[index].constraintFieldType = constraintFieldType;
    if (constraints) {
      newValues[index].constraints = constraints;
    }

    if (constraintValue !== null) {
      newValues[index].constraint = constraintValue;
    }
    return newValues;
  };

  // handles change for field select and sets possible operations based on it
  const handleFieldChange = (event, index) => {
    const values = [...inputFields];
    values[index].field = event.target.value;

    const { format } = reformattedCols[event.target.value];

    let validops = [];
    if (format === 'Float' || format === 'Int') {
      validops = numberOps;
    } else if (format === 'String') {
      validops = stringOps;
    } else if (format === 'Boolean') {
      validops = boolOps;
    } else if (format.constructor === Array) {
      validops = stringOps;
    } else if (format === 'AWSDateTime') {
      validops = awsdtOps;
    }

    values[index].operations = validops;
    if (!validops.includes(values[index].operation)) {
      values[index].operation = ''; // if the op selected isn't one that's valid, reset it
    }

    const newValues = updateConstraintTypes(format, values, index, values[index].operation);
    updateTablePreferenceLocalStorage(tablePrefix, 'filter', newValues, setInputFields);
  };

  const handleOpChange = (event, index) => {
    const values = [...inputFields];
    values[index].operation = event.target.value;

    const currformat = reformattedCols[inputFields[index].field].format;

    const newValues = updateConstraintTypes(currformat, values, index, event.target.value);

    updateTablePreferenceLocalStorage(tablePrefix, 'filter', newValues, setInputFields);
  };

  const handleConstraintChange = (event, index) => {
    const values = [...inputFields];
    values[index].constraint = event.target.value;
    // TODO call updateTablePreferenceLocalStorage()
    updateTablePreferenceLocalStorage(tablePrefix, 'filter', values, setInputFields);
    // setInputFields(values);
  };

  const handleDateTimeChange = (event, index) => {
    // eslint-disable-next-line no-underscore-dangle
    let newval = new Date(event._d);
    newval = newval.toISOString();
    const values = [...inputFields];
    values[index].constraint = newval;
    // TODO call updateTablePreferenceLocalStorage()
    updateTablePreferenceLocalStorage(tablePrefix, 'filter', values, setInputFields);
    // setInputFields(values);
  };

  const handleAdd = () => {
    // TODO call updateTablePreferenceLocalStorage()
    const newValues = [
      ...inputFields,
      {
        field: '',
        operation: '',
        operations: [],
        constraint: '',
        constraintFieldType: '',
        constraints: [],
      },
    ];
    updateTablePreferenceLocalStorage(tablePrefix, 'filter', newValues, setInputFields);
    // setInputFields(newValues);
  };

  const handleRemove = (index) => {
    if (inputFields.length >= 1) {
      const values = [...inputFields];
      values.splice(index, 1);
      // TODO call updateTablePreferenceLocalStorage()
      updateTablePreferenceLocalStorage(tablePrefix, 'filter', values, setInputFields);
      // setInputFields(values);
    }
  };

  const executeSearch = async () => {
    const allCriteria = inputFields;
    let filters = [];

    // filters should be a list - if we recieved just a single filter obj, put it in a list
    if (Array.isArray(defaultFilter)) {
      filters = defaultFilter;
    } else if (defaultFilter) {
      if (Object === defaultFilter.constructor) {
        filters = [defaultFilter];
      }
    }

    function formatQuery(current) {
      const { field, operation, constraint } = current;

      // must have value for field, op, and constraint to do a search
      if (field !== '' && operation !== '' && constraint !== '') {
        // if wildcard op (our version of contains) then we need to split it up into separate words if we got a phrase
        // spaces in this type of query breaks it!
        if (operation === 'wildcard') {
          const wordList = constraint.match(/\b(\w+)\b/g);
          wordList.forEach((word) => {
            const newfilter = {};
            newfilter[field] = {};
            newfilter[field][operation] = `*${word}*`;
            filters.push(newfilter);
          });
        } else {
          const newfilter = {};
          newfilter[field] = {};
          newfilter[field][operation] = constraint;
          filters.push(newfilter);
        }
      }
    }

    allCriteria.forEach((filter) => {
      formatQuery(filter);
    });

    // nest filters in format our appsync api wants
    const formattedFilters = APIWrapper.formatFilters(filters);
    setQueryFilters(formattedFilters);
  };

  useEffect(() => {
    tableColumnPreferenceLoader(tablePrefix, 'filter', defaultInputFields, setInputFields);

    // TODO 111323 update to handle case where the filter has been updated
    // const advFilters = getLocalStorageTablePref(tablePrefix, 'filter');
    // if (!advFilters) {
    //   updateTablePreferenceLocalStorage(tablePrefix, 'filter', defaultInputFields);
    //   setInputFields(defaultInputFields);
    // } else {
    //   setInputFields(advFilters);
    // }
  }, []);

  useEffect(() => {
    // when advance component appears, do an initial query so it has some data
    // define cases to handle
    // 1 no local storage for filter table pref (do nothing)
    // 2 there is local storage for filter table pref (empty/default settings) => run executeSearch()
    // 3 there is local storage for filter table pref (non-default settings) => run executeSearch()
    // if (getLocalStorageTablePref(tablePrefix, 'filter')) {
    executeSearch();
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('details', details);
      // Reset the state of your app so the error doesn't happen again
      }}
    >
      <div id="advanced-search">
        {inputFields.length === 0 && (
        <div>
          <IconButton onClick={handleAdd}>
            Add Search Criteria
            {' '}
            <Add />
          </IconButton>
        </div>
        )}
        {inputFields.map((item, index) => (
          <div
            key={index}
            className="search-select"
          >
            {inputFields.length >= 1 && (
            <IconButton onClick={() => handleRemove(index)}>
              <DeleteIcon />
            </IconButton>
            )}
            <FormControl variant="outlined">
              <InputLabel id="field">field</InputLabel>
              <Select
                key={`${index}-field`}
                onChange={(e) => handleFieldChange(e, index)}
                value={item.field || 'None'}
                label="field"
              >
                <MenuItem value="None">
                  <em>None</em>
                </MenuItem>
                {alphabetizedCols.map((col) => (
                  <MenuItem
                    key={col.field}
                    value={col.field}
                  >
                    {col.headerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel id="operation">operation</InputLabel>
              <Select
                key={`${index}-operation`}
                onChange={(e) => handleOpChange(e, index)}
                value={item.operation || 'None'}
                label="operation"
              >
                <MenuItem value="None">
                  <em>None</em>
                </MenuItem>
                {item.operations.map((op) => (
                  <MenuItem
                    key={op}
                    value={op}
                  >
                    {item.constraintFieldType === 'awsdatetime' && (
                      dtLabels[op]
                    )}
                    {item.constraintFieldType !== 'awsdatetime' && (
                      opLabels[op]
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              {
              item.constraintFieldType === 'text' && (
                <TextField
                  key={`${index}-input`}
                  label="text"
                  variant="outlined"
                  onChange={(e) => handleConstraintChange(e, index)}
                />
              )
            }
              {
              item.constraintFieldType === 'number' && (
                <TextField
                  key={`${index}-input`}
                  label="number"
                  variant="outlined"
                  onChange={(e) => handleConstraintChange(e, index)}
                />
              )
            }
              {
              item.constraintFieldType === 'select' && (
                <Select
                  key={`${index}-input`}
                  value={item.constraint || 'None'}
                  onChange={(e) => handleConstraintChange(e, index)}
                >
                  <MenuItem value="None">
                    <em>None</em>
                  </MenuItem>
                  {
                    item.constraints.map((c) => (
                      <MenuItem
                        key={c}
                        value={c}
                      >
                        {c}
                      </MenuItem>
                    ))
                  }
                </Select>
              )
            }
              {
              item.constraintFieldType === 'awsdatetime' && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    label="date"
                    // value={value}
                    value={moment(item.constraint) || moment(Date.now())}
                    onChange={(e) => handleDateTimeChange(e, index)}
                  />
                </LocalizationProvider>
              )
            }
            </FormControl>
            <IconButton onClick={handleAdd}>
              <Add />
            </IconButton>
          </div>
        ))}
        <Button variant="contained" onClick={executeSearch}>Search</Button>
        {checkboxSelection && (
        <ServerTable
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
          pageState={pageState}
          updatePageState={updatePageState}
          sortModel={sortModel}
          filters={queryFilters || {}}
          queryName={queryName}
          checkboxSelection
          createRows={createRows}
          setSelectedRows={setSelectedRows}
          setOrderedColumns={setOrderedColumns}
        />
        )}
        {!checkboxSelection && (
        <ServerTable
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
          pageState={pageState}
          updatePageState={updatePageState}
          sortModel={sortModel}
          filters={queryFilters || {}}
          queryName={queryName}
          createRows={createRows}
          tablePrefix={tablePrefix}
          setOrderedColumns={setOrderedColumns}
        />
        )}
      </div>
    </ErrorBoundary>
  );
}

export default AdvancedSearch;

AdvancedSearch.propTypes = {
  pageState: PropTypes.oneOfType([PropTypes.object]),
  updatePageState: PropTypes.func,
  columns: PropTypes.oneOfType([PropTypes.array]),
  sortModel: PropTypes.oneOfType([PropTypes.object]),
  columnVisibilityModel: PropTypes.oneOfType([PropTypes.object]),
  setColumnVisibilityModel: PropTypes.func,
  defaultFilter: PropTypes.oneOfType([PropTypes.object]),
  excludeFields: PropTypes.oneOfType([PropTypes.array]),
  queryName: PropTypes.string,
  checkboxSelection: PropTypes.bool,
  setSelectedRows: PropTypes.func,
  createRows: PropTypes.func,
  tablePrefix: PropTypes.string,
  pinnedColumns: PropTypes.oneOfType([PropTypes.object]),
  setOrderedColumns: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  getDefaultColumns: PropTypes.func,
};
