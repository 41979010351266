import { createTheme } from '@mui/material/styles';

const coreTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#00a69c',
      light: '#ccedeb',
      hoverLight: '#99dbd7',
    },
    secondary: {
      main: '#faf3eb',
      contrastText: '#292d2d',
      light: '#fbdda7',
      dark: '#db9217',
    },
    background: {
      default: '#fafafa',
    },
    error: {
      main: '#ef663d',
      mainHover: '#af330e',
    },
    info: {
      main: '#00437d',
    },
    text: {
      primary: '#292d2d',
      secondary: '#5e6262',
      disabled: '#949696',
      hint: '#949696',
    },
    indicator: {
      primary: '#292d2d',
      secondary: '#ffff',
    },
    warning: {
      main: '#6d44cc',
    },
    success: {
      main: '#00805b',
    },
    datagrid: {
      selectedOrHover: '#ccedeb',
      differentiation: '#F0F0F0',
      darkerSelect: '#d8e7e6',
    },
    divider: '#c9cbcb',
  },
  typography: {
    fontFamily: 'Archivo',
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#faf3eb',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#faf3eb',
          color: '#292d2d',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.Mui-selected': {
            fontWeight: 'bold',
          },
        },
        indicator: {
          color: '#292d2d',
        },
      },
    },
  },
});

const theme = createTheme(coreTheme, {
  palette: {
    tertiary: coreTheme.palette.augmentColor({
      color: {
        main: '#735a73',
      },
    }),
    name: 'tertiary',
  },
});

export default theme;
