import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';

import ClientCheckBox from '../clientFields/ClientCheckbox';
import ClientTextField from '../clientFields/ClientTextField';
import ClientDropDown from '../clientFields/ClientDropDown';
import formatDate from '../../utils/formatters';

function AdAITab({
  validateFunc,
  setFunction,
  data,
  hasAdAIAdminPermission,
  structure,
  timeStamps,
  permissions = {},
}) {
  const setter = setFunction;
  const [liveData, setLiveData] = useState({ ...data });
  const [userPermissions, setUserPermissions] = useState({ ...permissions });
  const [dataStructure, setDataStructure] = useState({ ...structure });
  const [formattedIsAdAIMember, setFormattedIsAdAIMember] = useState(null);

  useEffect(() => {
    setLiveData(data);
    setUserPermissions(permissions);
    setDataStructure(structure);
  }, [data]);

  useEffect(() => {
    if (timeStamps && timeStamps.is_advantage_member) {
      setFormattedIsAdAIMember(formatDate(timeStamps.is_advantage_member));
    }
    setLiveData(data);
    setDataStructure(structure);
  }, [data, timeStamps]);

  return (
    <div id="adai-columns">
      <div id="adai-info">
        <div className="timestamp">
          <ClientCheckBox
            setFunction={setter}
            data={liveData.is_advantage_member}
            attribute="is_advantage_member"
            label="Advantage AI Member"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasAdAIAdminPermission}
            testingId="adaicheckbox-test"
          />
          {formattedIsAdAIMember && (
          <p>
            Last update on
            {' '}
            {formattedIsAdAIMember}
          </p>
          )}
        </div>
        <ClientTextField
          setFunction={setter}
          data={liveData.advantageai_member_on_date}
          attribute="advantageai_member_on_date"
          label="Advantage AI Member Since"
          permissions={userPermissions}
          structure={dataStructure}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.suppressed_domains}
          attribute="suppressed_domains"
          label="Suppressed Domains"
          permissions={userPermissions}
          structure={dataStructure}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.share_cap_max_shares}
          attribute="share_cap_max_shares"
          label="Share Cap Max Shares"
          permissions={userPermissions}
          structure={dataStructure}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.share_cap_months}
          attribute="share_cap_months"
          label="Share Cap Months"
          permissions={userPermissions}
          structure={dataStructure}
        />
      </div>
      <div id="delivery-cards">
        <Card className="client-card" variant="outlined" sx={{ p: 3 }}>
          <h2>Donor Proportion Floor</h2>
          <ClientTextField
            validateFunc={validateFunc}
            setFunction={setter}
            data={liveData.donor_proportion_floor_value}
            attribute="donor_proportion_floor_value"
            label="Donor Floor"
            permissions={userPermissions}
            structure={dataStructure}
            inputAdornment="%"
            inputType="number"
          />
          <ClientDropDown
            setFunction={setter}
            data={liveData.donor_floor_type}
            attribute="donor_floor_type"
            label="Donor Floor Type"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasAdAIAdminPermission}
          />
        </Card>
        <Card
          id="adai-delivery-selections"
          className="client-card"
          variant="outlined"
          sx={{ p: 3 }}
        >
          <h2>Multi-Member Configuration</h2>
          <p>Multi-Member Clients</p>
          <ClientDropDown
            setFunction={setter}
            data={liveData.multi_member_clients
              ? liveData.multi_member_clients[0]
              : null}
            attribute="multi_member_clients"
            label="MM Client 1"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasAdAIAdminPermission}
          />
          <ClientDropDown
            setFunction={setter}
            data={liveData.multi_member_clients
              ? liveData.multi_member_clients[1]
              : null}
            attribute="multi_member_clients"
            label="MM Client 2"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasAdAIAdminPermission}
          />
          <ClientDropDown
            setFunction={setter}
            data={liveData.multi_member_clients
              ? liveData.multi_member_clients[2]
              : null}
            attribute="multi_member_clients"
            label="MM Client 3"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasAdAIAdminPermission}
          />
        </Card>
      </div>
    </div>
  );
}

export default AdAITab;

AdAITab.propTypes = {
  permissions: PropTypes.oneOfType([PropTypes.object]),
  structure: PropTypes.oneOfType([PropTypes.object]),
  setFunction: PropTypes.func,
  validateFunc: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  timeStamps: PropTypes.oneOfType([PropTypes.object]),
  hasAdAIAdminPermission: PropTypes.bool.isRequired,
};
