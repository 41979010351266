export const REACT_APP_ENVIRON = process.env.REACT_APP_ENVIRON
  ? process.env.REACT_APP_ENVIRON
  : 'staging';

export const authconfig = {
  domain: 'missionportal.us.auth0.com',
  clientId: '1Paz5Ggq9FvR5kW17sjMEFj2C5cBT073',
  audience: 'MissionPortal-DB',
};

export const awsconfig = {
  aws_project_region: 'us-east-1',
  aws_appsync_graphqlEndpoint: 'https://vhmn35xufrg7nexzsif2h4kr34.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'OPENID_CONNECT',
  aws_cognito_identity_pool_id: 'us-east-1:bd2af62b-1432-4884-8924-7fbe657012d3',
  aws_cognito_region: 'us-east-1',
};

const dromoFrontendLicense = {
  production: '9cce1b92-9df8-4b6d-904b-40f231513101',
  staging: '171ccc59-1c10-434d-9a39-e4ea3bba0445',
};

export const dromoFrontendLic = REACT_APP_ENVIRON === 'production'
  ? dromoFrontendLicense.production
  : dromoFrontendLicense.staging;
