/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, {
  useEffect, useState,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';

import FallbackOnError from '../components/FallbackOnError';
import {
  getLocalStorageTablePref, logReactErrBoundaryError, updateTablePreferenceLocalStorage, tableColumnPreferenceLoader,
} from '../utils';
import logger from '../utils/logger';
import TabPanel from '../components/TabPanel';
import theme from '../components/themes/Theme';
import AdvancedSearch from '../components/AdvancedSearch';
import { insightsCohortColumns, insightsNonCohortColumns, insightsReacCohortColumns } from '../utils/schemaInfo';
import { createInsightsCohortRows, createInsightsNonCohortRows, createInsightsReacCohortRows } from '../utils/tableHelpers';

function Insights() {
  const [pageState, setPageState] = useState({
    isLoading: true,
    page: 0, // mui pages are 0 indexed
    pageSize: 50,
    data: [],
    total: 0,
  });
  const { client } = useParams();

  useEffect(() => {
    document.title = 'MissionPortal | Insights: JIRA AAI Aggregated Results';
  }, []);

  const labelsAndQueries = {
    Acquisition: {
      'By JIRA Ticket': {
        query: 'searchInsightsAcqDeliveries',
        isCohort: false,
      },
      'By JIRA Ticket/Cohort': {
        query: 'searchInsightsAcqDeliveriesCohorts',
        isCohort: true,
      },
    },
    Advocacy: {
      'By JIRA Ticket': {
        query: 'searchInsightsAdvDeliveries',
        isCohort: false,
      },
      'By JIRA Ticket/Cohort': {
        query: 'searchInsightsAdvDeliveriesCohorts',
        isCohort: true,
      },
    },
    Reactivation: {
      'By JIRA Ticket': {
        query: 'searchInsightsReacDeliveries',
        isCohort: false,
      },
      'By JIRA Ticket/Cohort': {
        query: 'searchInsightsReacDeliveriesCohorts',
        isCohort: true,
      },
    },
  };

  const labelsList = Object.keys(labelsAndQueries);

  const cohortDescriptions = ['JIRA AAI Aggregated Results: Acquisition Cohorts', 'JIRA AAI Aggregated Results: Advocacy Cohorts', 'JIRA AAI Aggregated Results: Reactivation Cohorts'];
  // acqusition, advocacy, and reactivation
  const [categoryGroupTab, setCategoryGroupTab] = useState(0);
  const [currCategoryName, setCurrCategoryName] = useState(labelsList[categoryGroupTab]);

  // selection for (by Jira Ticket or By Jira Ticket/Cohort) for each category (acquisition, advocacy, and reactivation)
  const [bySelectSubTab, setBySelectSubTab] = useState([0, 0, 0]);
  const [currSubTab, setCurrSubTab] = useState(bySelectSubTab[categoryGroupTab]);
  const [currSubTabName, setCurrSubTabName] = useState(Object.keys(labelsAndQueries[currCategoryName])[currSubTab]);

  function getDefaultColumns(catName, subTabName) {
    /**
     * get columns to be sent to the AdvancedSearch component based on current tab/subtab selection.
     * @param {String} catName the current category tab name
     * @param {String} subTabName the current sub tab name
     *
     * @returns {Array} the columns that should display for the current cat/sub tab selection
     */
    if (catName === 'Reactivation' && subTabName === 'By JIRA Ticket/Cohort') {
      return insightsReacCohortColumns;
    }
    return labelsAndQueries[catName][subTabName].isCohort ? insightsCohortColumns : insightsNonCohortColumns;
  }

  const [currCols, setCurrCols] = useState(getDefaultColumns(currCategoryName, currSubTabName) || []);

  const defaultSortModel = { field: 'updated_dt', direction: 'desc' };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const [filterModel, setFilterModel] = useState({ items: [] });

  const updateUserPreferences = () => {
    const currTablePrefix = `insights-${labelsAndQueries[currCategoryName][currSubTabName].query}`;
    const listFilters = getLocalStorageTablePref(currTablePrefix, 'filter');
    if (listFilters) {
      setFilterModel(listFilters);
    }

    const defaultCols = getDefaultColumns(currCategoryName, currSubTabName);
    // this usage differs from other tables because Insights doesn't store user preference for column order
    // in react state
    tableColumnPreferenceLoader(currTablePrefix, 'column', defaultCols);

    // const listSortOrder = getLocalStorageTablePref(currTablePrefix, 'sort');
    // if (!listSortOrder) {
    //   updateTablePreferenceLocalStorage(currTablePrefix, 'sort', defaultSortModel);
    // } else {
    //   setSortModel(listSortOrder);
    // }

    const listColumnVisibility = getLocalStorageTablePref(currTablePrefix, 'column-visibility');
    if (!listColumnVisibility) {
      const defCols = getDefaultColumns(currCategoryName, currSubTabName);

      const fieldList = defCols.map((col) => col.field);
      const initColumnVisibilityModel = Object.fromEntries(fieldList.map((k) => [k, true]));

      updateTablePreferenceLocalStorage(currTablePrefix, 'column-visibility', initColumnVisibilityModel);
    } else {
      setColumnVisibilityModel(listColumnVisibility);
    }
  };

  // change of acquisition, advocacy, recactivation tab
  const handleCategoryChange = (event, newValue) => {
    const newGroupName = labelsList[newValue];
    const newSubTab = bySelectSubTab[newValue];
    setCurrCategoryName(newGroupName);
    setCategoryGroupTab(newValue);
    setCurrSubTab(newSubTab);
    const newSubTabName = Object.keys(labelsAndQueries[newGroupName])[newSubTab];
    setCurrSubTabName(newSubTabName);
    setCurrCols(getDefaultColumns(newGroupName, newSubTabName));
  };

  // cohort or non cohort sub tab
  const handleCohortChange = (index, event, newValue) => {
    const newSubTab = [...bySelectSubTab];
    newSubTab[index] = newValue;
    setCurrSubTab(newValue);
    const newSubTabName = Object.keys(labelsAndQueries[currCategoryName])[newValue];
    setCurrSubTabName(newSubTabName);
    setBySelectSubTab(newSubTab);
    setCurrCols(getDefaultColumns(currCategoryName, newSubTabName));
  };

  const createRowsFuncSelector = (catName, subTabName) => {
    /**
     * select create rows function to be sent to AdvancedSearch component based on current tab/subtab selection.
     * @param {String} catName the current category tab name, from state
     * @param {String} subTabName the current sub tab name, from state
     *
     * @returns {Function} the correct create rows function for the current table
     */
    if (catName === 'Reactivation' && subTabName === 'By JIRA Ticket/Cohort') {
      return createInsightsReacCohortRows;
    }
    return labelsAndQueries[catName][subTabName].isCohort === true ? createInsightsCohortRows : createInsightsNonCohortRows;
  };

  useEffect(() => {
    updateUserPreferences();
  }, [categoryGroupTab, currCategoryName, currSubTab, currSubTabName]);

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('details', details);
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <Box sx={{ width: '100%' }}>
        {/* black text label for JIRA AAI */}
        <h2>{cohortDescriptions[categoryGroupTab]}</h2>
        {/* acqusition, advocacy, reactivation green tabs */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs onChange={handleCategoryChange} value={categoryGroupTab} aria-label="insights tabs">
            {Object.keys(labelsAndQueries).map((label, index) => (
              <Tab label={<span>{label}</span>} value={index} key={`${labelsAndQueries[label]}-${index}`} />
            ))}
          </Tabs>
        </Box>
        <TabPanel
          label={currCategoryName}
          value={categoryGroupTab}
          index={categoryGroupTab}
          key={cohortDescriptions[categoryGroupTab]}
        >
          <div>
            <Tabs
              onChange={(e, val) => handleCohortChange(categoryGroupTab, e, val)}
              value={currSubTab}
              centered
              sx={{
                '.Mui-selected': {
                  color: theme.palette.info.main,
                },
              }}
              TabIndicatorProps={{
                style: {
                  background: theme.palette.info.main,
                },
              }}
              key={currSubTabName}
            >
              {/* cohort or non cohort */}
              {Object.keys(labelsAndQueries[currCategoryName]).map((bySelection, byIndex) => (
                <Tab label={bySelection} value={byIndex} key={byIndex} />
              ))}
            </Tabs>
            <TabPanel
              label={currSubTabName}
              value={currSubTab}
              index={currSubTab}
              customSX={{
                backgroundColor: theme.palette.background.default,
              }}
              key={currSubTab}
            >
              <AdvancedSearch
                pinnedColumns={{ pinnedColumns: { left: ['updated_dt', 'client_type', 'client', 'jira_ticket_num', 'first_delivery_date'] } }}
                columns={currCols}
                getDefaultColumns={() => getDefaultColumns(currCategoryName, currSubTabName)}
                setOrderedColumns={setCurrCols}
                sortModel={defaultSortModel}
                columnVisibilityModel={columnVisibilityModel}
                setColumnVisibilityModel={setColumnVisibilityModel}
                filterModel={filterModel}
                setFilterModel={setFilterModel}
                pageState={pageState}
                updatePageState={setPageState}
                queryName={labelsAndQueries[currCategoryName][currSubTabName].query}
                createRows={createRowsFuncSelector(currCategoryName, currSubTabName)}
                tablePrefix={`insights-${labelsAndQueries[currCategoryName][currSubTabName].query}`}
                defaultFilter={
                    [{ client: { eq: client } }]
                  }
                excludeFields={['client']}
              />
            </TabPanel>
          </div>
        </TabPanel>
      </Box>
    </ErrorBoundary>
  );
}

export default Insights;
