function formatDate(timeStampInfo) {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'America/New_York',
  };

  try {
    let datetime = timeStampInfo.updatedAt;
    datetime = new Date(datetime);
    const formatteddatetime = `${datetime.toLocaleDateString(
      'en-US',
      options,
    )} EST`;
    return formatteddatetime;
  } catch (e) {
    return null;
  }
}

export default formatDate;
