import React, {
  useState, cloneElement, useContext, useEffect,
} from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// MUI elements
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

// Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import PaidIcon from '@mui/icons-material/Paid';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { History, Insights, Star } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import logo from '../assets/portal-logo.png';
import AppContext from '../context';
import APIWrapper from '../utils/graphqlwrapper';
import logger from '../utils/logger';
import { addRecentClient, getFavoriteClients, getRecentClients } from '../utils/favsAndRecentClients';
import NotFound from '../views/NotFound';

const drawerWidth = 275;

const openedMixin = (theme) => ({
  // sidebar open width, animations
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  // animation/width sidebar shutting
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  // this is for the little menu icon
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  // top menu bar
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  // side bar!
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

// to dynamically load icons for each page in the side menu
function DynamIcon({ index, icons }) {
  const IconComponent = icons[index];
  return IconComponent ? <IconComponent /> : null;
}

let prevClientName = '';

export default function Menu(props) {
  const { body: menuBody } = props;
  const ctx = useContext(AppContext);
  const [favList, setFavClients] = useState(getFavoriteClients() || []);
  const [recentList, setRecentClients] = useState(getRecentClients() || []);
  const { user } = ctx;
  ctx.setFavClients = setFavClients;
  ctx.favClientsList = favList;
  ctx.setRecentClients = setRecentClients;
  const { state } = useLocation();

  const [clientName, setClientName] = useState('');
  let client;
  if (state) {
    client = state.client;
  }
  const [newClient, setNewClient] = useState(false);
  // Menu includes BOTH the top menu bar and side nav
  const navigate = useNavigate();

  const theme = useTheme();
  const [open, setOpen] = useState(
    localStorage.getItem('menu-open') === 'true',
  );
  const [favoritesOpen, setFavoritesOpen] = useState(
    localStorage.getItem('fav-menu-open') === 'true',
  );
  const [recentsOpen, setRecentsOpen] = useState(
    localStorage.getItem('recent-menu-open') === 'true',
  );

  // for reporting
  const [openSubmenu, setOpenSubmenu] = React.useState(false);
  const [openSubmenu2, setOpenSubmenu2] = React.useState(false);
  const [openSubmenu3, setOpenSubmenu3] = React.useState(false);
  const [openSubmenu4, setOpenSubmenu4] = React.useState(false);
  const [openSubmenu5, setOpenSubmenu5] = React.useState(false);

  const handleClick = (idx) => {
    if (idx === 0) {
      setOpenSubmenu(!openSubmenu);
    } else if (idx === 1) {
      setOpenSubmenu2(!openSubmenu2);
    } else if (idx === 2) {
      setOpenSubmenu3(!openSubmenu3);
    } else if (idx === 3) {
      setOpenSubmenu4(!openSubmenu4);
    } else {
      setOpenSubmenu5(!openSubmenu5);
    }
  };

  let pages = [];
  let goto;
  let icons;
  let titles;

  // splits path into array delimited by /
  const pathArray = window.location.pathname.split('/').filter((element) => element);

  if (pathArray[0] === 'clients') {
    pages = ['Manage Client', 'Source Management', 'Transactions', 'Insights'];
    // eslint-disable-next-line prefer-destructuring
    client = pathArray[1];
    goto = [
      `/clients/${client}`,
      `/clients/${client}/sources`,
      `/clients/${client}/transactions`,
      `/clients/${client}/insights`,
      `/clients/${client}/reporting`,
    ];
    icons = [SettingsIcon, LibraryAddCheckIcon, PaidIcon, Insights];
    titles = ['Manage Client', 'Source Management', 'Transactions'];
  }

  const toggleOpen = () => {
    localStorage.setItem('menu-open', !open);
    setOpen(!open);
  };

  // if the user clicks on fav or recent when the menu is closed, open menu and uncollapse the list
  const forceOpen = (collapseitem) => {
    localStorage.setItem('menu-open', true);
    setOpen(true);
    if (collapseitem === 'favorites') {
      setFavoritesOpen(true);
      localStorage.setItem('fav-menu-open', true);
    } else if (collapseitem === 'recents') {
      setRecentsOpen(true);
      localStorage.setItem('recent-menu-open', true);
    }
  };

  const [dne, setDNE] = useState(false);

  const getClientInfo = async (clientId) => {
    const result = await APIWrapper.queryApi(
      { query: 'getClient' },
      false,
      { client_id: clientId },
    )
      .then((resp) => {
        try {
          if (resp.data[0].length === 0) {
            setDNE(true);
          } else {
            if (pathArray[1]) {
              addRecentClient(pathArray[1]);
            }
            setDNE(false);
          }
          if (resp.data[0][0].client_full_name) {
            prevClientName = (resp.data[0][0].client_full_name);
            setClientName(resp.data[0][0].client_full_name);
          }
          if (resp.data[0][0].client_id) {
            // add it to recently visited pages
            addRecentClient(resp.data[0][0].client_id);
          }
        } catch (e) {
          setClientName(clientId);
          prevClientName = (clientId);
        }
      })
      .catch((err) => logger.error(err));
    return result;
  };

  useEffect(() => {
    const currPathArray = window.location.pathname.split('/').filter((element) => element);
    if (currPathArray[0] === 'clients') {
      if (prevClientName !== client) {
        prevClientName = client;
        setNewClient(client);
        getClientInfo(client);
      }
    }
  }, [window.location.pathname]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} elevation={0}>
        {/* Toolbar is the top menu */}
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {clientName ? <h2>{clientName}</h2> : <h2>MissionWired</h2>}
          <div className="appbar-avatar-container">
            <IconButton onClick={() => navigate('/profile')}>
              <Avatar>
                {(user ? user.given_name[0] : false) || ':)'}
              </Avatar>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {/* Drawer is the side menu */}
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {/* little arrow in the side bar  */}
          <IconButton onClick={toggleOpen}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        {/* MissionPortal logo in side nav */}
        <div className="missionportal-logo-container">
          <a className="missionportal-logo" href="/clientlist">
            <img
              alt="MissionPortal logo"
              src={logo}
              style={{
                width: open ? '50px' : '30px',
              }}
            />
          </a>
        </div>
        <List>
          {client && (
          <ListItem
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemText
              primary={client && client.length ? client.toUpperCase() : ''}
              sx={{ opacity: open ? 1 : 0, textAlign: 'center' }}
            />
          </ListItem>
          )}
          <Divider variant="middle" />
          {client
            && pages.map((text, index) => (
              <Tooltip title={titles[index]} placement="right-start" key={text}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  onClick={() => navigate(goto[index], { state: { client } })}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <DynamIcon index={index} icons={icons} />
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </Tooltip>
            ))}
          {client && (
            <Divider variant="middle" />
          )}

          <Tooltip title="Client List" placement="right-start">
            <ListItemButton
              key="Client List"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => navigate('/clientlist')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText
                primary="Client List"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Tooltip>

          <Divider variant="middle" />
          {open && (
            <>
              {favList.length > 0 && (
                <>
                  <Tooltip title="Favorited Clients" placement="right-start">
                    <ListItemButton
                      key="Favorited Clients"
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                      onClick={() => {
                        localStorage.setItem('fav-menu-open', !favoritesOpen);
                        setFavoritesOpen(!favoritesOpen);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        <Star />
                      </ListItemIcon>
                      <ListItemText
                        primary="Favorited Clients"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      {favoritesOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </Tooltip>
                  <Collapse in={favoritesOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {favList.sort().map((clientCode) => (
                        <ListItemButton
                          sx={{ pl: 4 }}
                          key={clientCode}
                          onClick={() => navigate(`/clients/${clientCode}`)}
                        >
                          <ListItemIcon />
                          <ListItemText
                            primary={clientCode}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </>
              )}
              {recentList.length > 0 && (
                <>
                  <Tooltip title="Recent Clients" placement="right-start">
                    <ListItemButton
                      key="Recent Clients"
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                      onClick={() => {
                        localStorage.setItem('recent-menu-open', !recentsOpen);
                        setRecentsOpen(!recentsOpen);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        <History />
                      </ListItemIcon>
                      <ListItemText
                        primary="Recent Clients"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      {recentsOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </Tooltip>
                  <Collapse in={recentsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {recentList.slice().reverse().map((clientCode) => (
                        <ListItemButton
                          sx={{ pl: 4 }}
                          key={clientCode}
                          onClick={() => navigate(`/clients/${clientCode}`)}
                        >
                          <ListItemIcon />
                          <ListItemText primary={clientCode} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </>
              )}
            </>
          )}
          {!open && (
            <>
              <ListItemButton
                key="Favorite Clients Closed"
                sx={{
                  minHeight: 48,
                  justifyContent: 'center',
                  px: 2.5,
                }}
              >

                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Star onClick={() => forceOpen('favorites')} />
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton
                key="Recent Clients"
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <History onClick={() => forceOpen('recents')} />
                </ListItemIcon>
              </ListItemButton>
            </>
          )}

          <Divider variant="middle" />
          <Tooltip title="Dashboards and Reporting" placement="right-start">
            <ListItemButton
              key="Dashboards and Reporting"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => navigate('/reporting')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText
                primary="Dashboards and Reporting"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Tooltip>

          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClick(0)}>
              <ListItemText inset primary="Acquisition" />
              {openSubmenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => navigate('/reporting/acquisition_performance')}
              >
                <ListItemText inset primary="Performance" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => navigate('/reporting/acquisition_inventory')}
              >
                <ListItemText inset primary="Inventory" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => navigate('/reporting/acquisition_delivery_metrics')}
              >
                <ListItemText inset primary="Delivery Metrics" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => navigate('/reporting/acquisition_internal_performance')}
              >
                <ListItemText inset primary="Internal Performance" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClick(1)}>
              <ListItemText inset primary="Reactivation" />
              {openSubmenu2 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={openSubmenu2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => navigate('/reporting/reactivation_performance')}
              >
                <ListItemText inset primary="Performance" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => navigate('/reporting/reactivation_inventory')}
              >
                <ListItemText inset primary="Inventory" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClick(3)}>
              <ListItemText inset primary="Co-op Dashboards" />
              {openSubmenu4 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={openSubmenu4} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => navigate('/reporting/coop_dashboards_client_revenue')}
              >
                <ListItemText inset primary="Client Revenue" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => navigate('/reporting/coop_dashboards_political_marketing_aai')}
              >
                <ListItemText inset primary="Political Marketing" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClick(4)}>
              <ListItemText inset primary="Strategy Dashboards" />
              {openSubmenu5 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={openSubmenu5} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => navigate('/reporting/strategy_deliverability')}
              >
                <ListItemText inset primary="Deliverability" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => navigate('/reporting/strategy_champy_ads')}
              >
                <ListItemText inset primary="Champy Ads" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => navigate('/reporting/strategy_client_revenue')}
              >
                <ListItemText inset primary="Monthly Revenue" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: `calc(100% - ${drawerWidth}px)` }}>
        <DrawerHeader />
        {dne && (
          <NotFound />
        )}
        {!dne && (
        <>
          {cloneElement(menuBody, { key: newClient })}
        </>
        )}
        {/* This is the main content/body of the app. This component is passed in as props. */}
      </Box>
    </Box>
  );
}

Menu.propTypes = {
  body: PropTypes.element.isRequired,
};

DynamIcon.propTypes = {
  index: PropTypes.number.isRequired,
  icons: PropTypes.oneOfType([PropTypes.array]).isRequired,
};
