// used to sync source value changes (triggered from Sources table but needed to cascade
// to rows on Codes table)
const getCodeRowsToUpdate = (curCodesRows, curUpdatedSource) => {
  const updatedCodeRows = [];
  curCodesRows.forEach((r) => {
    if (r.source === curUpdatedSource.oldValue) {
      const updatedRow = { ...r, source: curUpdatedSource.newValue };
      updatedCodeRows.push(updatedRow);
    }
  });
  return updatedCodeRows;
};

export default getCodeRowsToUpdate;
