import React, { useEffect, useState, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Amplify, Auth } from 'aws-amplify';
import { ThemeProvider } from '@mui/material/styles';
import APIWrapper from './utils/graphqlwrapper';
import AppContext from './context';
import { authconfig, awsconfig } from './aws-config';

import ProtectedRoute from './utils/ProtectedRoute';
import logger from './utils/logger';

// components
import Menu from './components/Menu';
import Loading from './components/Loading';

// views
import Profile from './views/Profile';
import ClientList from './views/ClientList';
import ClientInfo from './views/ClientInfo';
import SourceManagement from './views/SourceManagement';
import Reporting from './views/Reporting';
import ReportingLanding from './views/ReportingLanding';
import ReportingDashboard from './views/ReportingDashboard';
import TransactionsContainer from './views/TransactionsContainer';
import SettingsPage from './views/Settings';
import CallbackPage from './views/Callback';
import NotFound from './views/NotFound';
import Unauthorized from './views/401';
import theme from './components/themes/Theme';
import {
  STRATEGY_DASHBOARD_CLIENT_REVENUE_REPORT_PARAMS,
  ACQUISITION_PERFORMANCE_REPORT_PARAMS,
  ACQUISITION_INTERNAL_PERFORMANCE_REPORT_PARAMS,
  REACTIVATION_PERFORMANCE_REPORT_PARAMS,
  COOP_DASHBOARDS_POLITICAL_MARKETING_REPORT_PARAMS,
} from './utils/reportingConstants';

// auth/protect
import Login from './utils/Login';
import Logout from './utils/Logout';

// styles
import './App.scss';
import AdvancedSearch from './components/AdvancedSearch';
import Insights from './views/Insights';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

function App() {
  const {
    user,
    isAuthenticated,
    getIdTokenClaims,
    isLoading,
  } = useAuth0();
  const [shouldLogOut, setShouldLogOut] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  // useMemo for context will prevent unnecessary rerenders when user and authenticated
  // values have not changed. All future context values should be added here.
  const contextValues = useMemo(
    () => ({
      user,
      authenticated,
      setAuthenticated,
      setShouldLogOut,
    }),
    [user, authenticated, shouldLogOut],
  );
  const [token, setToken] = useState(null);

  const AdAIAdmins = [
    'ambika@missionwired.com',
    'mary.sulaiman@missionwired.com',
    'brook.denny@missionwired.com',
    'todd@missionwired.com',
    'nathan.chong@missionwired.com',
    'ryan.rasmussen@missionwired.com',
    'alisha.edington@missionwired.com',
    'avery.blair@missionwired.com',
    'caitlin.mcmahon@missionwired.com',
    'jadiva.montealegre@missionwired.com',
    'isidro.camacho@missionwired.com',
  ];

  const dataEngineers = [
    'kelsey.evans@missionwired.com',
    'danya.levy@missionwired.com',
    'peter.mason@missionwired.com',
    'kaylin.dee@missionwired.com',
    'edgar.reyes@missionwired.com',
    'alexis.tatore@missionwired.com',
  ];
  let hasAdAIAdminPermission = false;
  let hasDataEngineerPermission = false;
  if (user) {
    hasAdAIAdminPermission = AdAIAdmins.indexOf(user.email) > -1;
    hasDataEngineerPermission = dataEngineers.indexOf(user.email) > -1;
  }

  useEffect(() => {
    async function doFedSignIn(idToken) {
      Auth.federatedSignIn(
        authconfig.domain, // The Auth0 Domain,
        {
          // the double dangle is how the data is returned, must disable
          // potentially we can set a rule for specific allowed dangle instances
          // eslint-disable-next-line no-underscore-dangle
          token: idToken.__raw, // The id token from Auth0
          expires_at: idToken.exp * 1000, // the expiration timestamp
        },
        user,
      )
        .then(() => {
          Auth.currentAuthenticatedUser();
        })
        .then(() => {
          Auth.currentUserCredentials();
          setUserEmail(user.email);
        })
        .then(() => {
          logger.debug(
            'Federated sign in completed. User is completely logged in',
            user,
          );
          setAuthenticated(true);
        })
        .catch((e) => {
          logger.error(e);
        });
    }

    const getAuth0Token = async () => {
      await getIdTokenClaims().then((resp) => {
        logger.debug(resp);
        if (!isLoading && resp) {
          Auth.signOut().then(() => {
            // doing a federated login in twice can cause issues,
            // so let's make sure they're logged out
            doFedSignIn(resp);
          });
        }
        return resp;
      });
    };

    getAuth0Token();
  }, [getIdTokenClaims, isLoading, user]);

  useEffect(() => {
    const isMenuOpen = localStorage.getItem('menu-open');
    if (isMenuOpen === null) {
      localStorage.setItem('menu-open', true);
    }
  }, []);

  useEffect(() => {
    async function getToken() {
      const payload = {
        userEmail,
      };

      const input = JSON.stringify(payload);

      let tok;
      try {
        await APIWrapper.queryApi(
          { query: 'getTokenTC' },
          setShouldLogOut,
          { input },
        ).then((resp) => {
          tok = JSON.parse(resp.data[0][0]);
          setToken(tok.body);
        }).catch((e) => logger.error(e));
      } catch (err) {
        logger.error(err);
      }
    }

    if (authenticated) {
      getToken();
    }
  }, [authenticated]);

  if (isLoading) {
    return <Loading />;
  }
  if (shouldLogOut) {
    return (
      <AppContext.Provider value={contextValues}>
        <Logout />
      </AppContext.Provider>
    );
  }
  if (!isLoading) {
    logger.debug('isAuthenticated', isAuthenticated);
    getIdTokenClaims().then((resp) => {
      logger.debug(resp);
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={contextValues}>
        <Routes>
          {['/', '/dashboard', '/clientlist', '/home'].map((path) => (
            <Route
              key="homepage" // doesn't unmount components
              path={path}
              element={(
                <ProtectedRoute
                  component={Menu}
                  body={(
                    <ClientList
                      hasAdAIAdminPermission={hasAdAIAdminPermission}
                    />
                  )}
                />
              )}
            />
          ))}
          <Route
            path="*"
            element={<ProtectedRoute component={Menu} body={<NotFound />} />}
          />
          <Route
            path="/profile"
            element={<ProtectedRoute component={Menu} body={<Profile />} />}
          />
          <Route
            path="/clients/:client"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={
                  (
                    <ClientInfo
                      hasAdAIAdminPermission={hasAdAIAdminPermission}
                      hasDataEngineerPermission={hasDataEngineerPermission}
                    />
                  )
                  }
              />
              )}
          />
          <Route
            path="/clients/:client/sources"
            exact
            element={
              <ProtectedRoute component={Menu} body={<SourceManagement />} />
            }
          />
          <Route
            path="/clients/:client/transactions"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <TransactionsContainer
                    token={token}
                    authenticated={authenticated}
                  />
                )}
              />
            )}
          />
          <Route
            path="/clients/:client/insights"
            exact
            element={
              <ProtectedRoute component={Menu} body={<Insights />} />
            }
          />

          {/* placeholder for the report/dashboard landing page */}
          <Route
            path="/reporting"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ReportingLanding />
                )}
              />
            )}
          />

          <Route
            path="/reporting/acquisition_performance"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ReportingDashboard
                    resc={ACQUISITION_PERFORMANCE_REPORT_PARAMS}
                    token={token}
                  />
                )}
              />
            )}
          />

          <Route
            path="/reporting/acquisition_inventory"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <Reporting
                    isAuth={authenticated}
                    url="https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/AAI-QualifiedInventorywtowerdata/AAI-QualifiedInventory?:iid=3"
                    token={token}
                    heading="Acquisition"
                    subheading="Inventory"
                  />
                )}
              />
            )}
          />

          <Route
            path="/reporting/acquisition_delivery_metrics"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <Reporting
                    isAuth={authenticated}
                    url="https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/AdAICohortDonorMetrics/Dashboard1?:iid=6"
                    heading="Acquisition"
                    subheading="Delivery Metrics"
                  />
                )}
              />
            )}
          />

          <Route
            path="/reporting/acquisition_internal_performance"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ReportingDashboard
                    resc={ACQUISITION_INTERNAL_PERFORMANCE_REPORT_PARAMS}
                    token={token}
                  />
                )}
              />
            )}
          />

          <Route
            path="/reporting/reactivation_performance"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ReportingDashboard
                    resc={REACTIVATION_PERFORMANCE_REPORT_PARAMS}
                    token={token}
                  />
                )}
              />
            )}
          />

          <Route
            path="/reporting/reactivation_inventory"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <Reporting
                    isAuth={authenticated}
                    url="https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ReactivationInventory_16575591554810/ReactivationInventory?:iid=5"
                    token={token}
                    heading="Reactivation"
                    subheading="Inventory"
                  />
                )}
              />
            )}
          />

          <Route
            path="/reporting/coop_dashboards_client_revenue"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <Reporting
                    isAuth={authenticated}
                    url="https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ClientRevenue/ClientRevenue?:iid=5"
                    token={token}
                    heading="Co-op Dashboards"
                    subheading="Client Revenue"
                  />
                )}
              />
            )}
          />

          <Route
            path="/reporting/coop_dashboards_political_marketing_aai"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ReportingDashboard
                    resc={COOP_DASHBOARDS_POLITICAL_MARKETING_REPORT_PARAMS}
                    token={token}
                  />
                )}
              />
            )}
          />

          <Route
            path="/reporting/strategy_deliverability"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <Reporting
                    isAuth={authenticated}
                    url="https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/DeliverabilityDashboardDateLevel/Overview?:iid=4"
                    token={token}
                    heading="Strategy Dashboards"
                    subheading="Deliverability"
                  />
                )}
              />
            )}
          />

          <Route
            path="/reporting/strategy_champy_ads"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <Reporting
                    isAuth={authenticated}
                    url="https://prod-useast-a.online.tableau.com/#/site/missionwiredtableau/views/ChampyAdsResults_16714860218040/KPI-CampaignAudience?:iid=1"
                    token={token}
                    heading="Strategy Dashboards"
                    subheading="Champy Ads"
                  />
                )}
              />
            )}
          />

          <Route
            path="reporting/strategy_client_revenue"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ReportingDashboard
                    resc={STRATEGY_DASHBOARD_CLIENT_REVENUE_REPORT_PARAMS}
                    token={token}
                  />
                )}
              />
            )}
          />

          <Route
            path="/settings"
            exact
            element={
              <ProtectedRoute component={Menu} body={<SettingsPage />} />
            }
          />
          <Route
            path="/newclient"
            exact
            element={(
              <ProtectedRoute
                component={Menu}
                body={(
                  <ClientInfo
                    isNew
                    hasAdAIAdminPermission={hasAdAIAdminPermission}
                    hasDataEngineerPermission={hasDataEngineerPermission}
                  />
                )}
              />
            )}
          />
          <Route
            path="/advanced"
            exact
            element={
              <ProtectedRoute component={Menu} body={<AdvancedSearch />} />
            }
          />
          <Route path="/callback" element={<CallbackPage />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/logout" exact element={<Logout />} />
          <Route path="/401" exact element={<Unauthorized />} />
        </Routes>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
