import React, {
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Codes from '../components/client/Codes';
import Sources from '../components/client/Sources';
import logger from '../utils/logger';
import TabPanel from '../components/TabPanel';
import { logReactErrBoundaryError } from '../utils';
import FallbackOnError from '../components/FallbackOnError';

function SourceManagement() {
  const { client } = useParams();
  const [tab, setTab] = useState(0);
  const [codesPageState, setCodesPageState] = useState({
    page: 0, // mui pages are 0 indexed
    pageSize: 50,
    data: [],
    total: 0,
    isPreview: false,
    previewData: [],
  });
  const [sourcesPageState, setSourcesPageState] = useState({
    page: 0, // mui pages are 0 indexed
    pageSize: 50,
    data: [],
    total: 0,
  });

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('details', details);
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <div>
        <header className="App-header">
          <h2>Source Management</h2>
        </header>
        <Box sx={{ width: '100%' }}>
          <>
            {' '}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleChange}>
                <Tab label="Sources" />
                <Tab label="Codes" />
              </Tabs>
            </Box>
            <>
              <TabPanel label="Sources" value={tab} index={0}>
                <Sources
                  client={client}
                  codesRows={codesPageState.data}
                  setCodesPageState={setCodesPageState}
                  sourcesPageState={sourcesPageState}
                  updatePageState={setSourcesPageState}
                />
              </TabPanel>
              <TabPanel label="Codes" value={tab} index={1}>
                <Codes
                  client={client}
                  codesPageState={codesPageState}
                  updatePageState={setCodesPageState}
                />
              </TabPanel>
            </>
          </>
        </Box>
      </div>
    </ErrorBoundary>
  );
}

export default SourceManagement;
