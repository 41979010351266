import React, {
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import {
  ACQUISITION_LINKS, REACTIVATION_LINKS,
  COOP_DASHBOARDS_LINKS, STRATEGY_DASHBOARDS_LINKS,
} from '../utils/reportingConstants';

function LinkList(props) {
  const { heading, links } = props;
  const navigate = useNavigate();

  return (
    <div>
      <h3>{heading}</h3>
      <ButtonGroup
        orientation="vertical"
        aria-label="vertical outlined button group"
        variant="contained"
      >
        {links.map((link) => (
          <Button
            key={link.url}
            value={link.url}
            onClick={() => navigate(link.url)}
            size="large"
            style={{ minWidth: '250px' }}
          >
            {link.name}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
function ReportingLanding(props) {
  // token should get used eventually
  // const { token } = props;

  useEffect(() => {
    document.title = 'MissionPortal | Reporting';
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h2>Dashboards and Reporting</h2>
      </header>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container display="flex" spacing={2} justifyContent="center">
          <Grid xs={12} sm={12} md={6} lg={4}>
            <LinkList heading="Acquisition" links={ACQUISITION_LINKS} />
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={4}>
            <LinkList heading="Reactivation" links={REACTIVATION_LINKS} />
            <LinkList heading="Co-op Dashboards" links={COOP_DASHBOARDS_LINKS} />
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={4}>
            <LinkList heading="Strategy Dashboards" links={STRATEGY_DASHBOARDS_LINKS} />
          </Grid>
        </Grid>
      </Box>

    </div>
  );
}

export default ReportingLanding;

LinkList.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

// token should get used eventually
// ReportingLanding.propTypes = {
//   token: PropTypes.string,
// };
