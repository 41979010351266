import React, {
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import FallbackOnError from '../components/FallbackOnError';
import { logReactErrBoundaryError } from '../utils';
import logger from '../utils/logger';

function Reporting(props) {
  const {
    token, url, heading, subheading, hideBreadcrumb,
  } = props;

  useEffect(() => {
    document.title = 'MissionPortal | Reporting';
  }, []);

  const breadCrumb = hideBreadcrumb ? null : (
    <header className="App-header">
      <h2>
        Dashboards and Reporting &gt;
        {' '}
        {heading}
        {' '}
        &gt;
        {' '}
        {subheading}
      </h2>
    </header>
  );

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('details', details);
        // Reset the state of your app so the error doesn't happen again
      }}
    >

      <div className="App">
        {breadCrumb}
        <div>
          {/* original */}
          <tableau-viz
            id="tableau-viz"
          // This is the link to the reporting view, this link comes from our Tableau Cloud
            src={url}
            width="100%"
            height="840"
            hide-tabs
            toolbar="top"
            token={token}
          />

        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Reporting;

Reporting.propTypes = {
  token: PropTypes.string,
  url: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  hideBreadcrumb: PropTypes.bool,
};
