import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  getRowIdxForId, getLocalStorageTablePref, updateTablePreferenceLocalStorage, diffListOfObjects,
} from '../../utils/index';
import ClientTextField from '../clientFields/ClientTextField';
import ClientDropDown from '../clientFields/ClientDropDown';
import MPAutocomplete from '../clientFields/MPAutocomplete';
import logger from '../../utils/logger';
import APIWrapper from '../../utils/graphqlwrapper';
import { codeColumns } from '../../utils/schemaInfo';
import AppContext from '../../context';
import { createCodeRows } from '../../utils/tableHelpers';
import AdvancedSearchV2 from '../AdvancedSearchV2';
import AdvancedSearchChip from '../AdvancedSearchChip';
import ServerTable from '../ServerTable';
import Loading from '../Loading';

const initColumnVisibilityModel = { from_goop: false, createdAt: false };
const structure = {
  type: [
    'Select one',
    'acquisition',
    'revenue',
    'acquisition_d2d',
    'revenue_d2d',
    'Fundraising',
    'Joint_Action',
    'Bundling',
    'Survey',
    'Other',
    'Petition',
    'Action',
    'p2p',
    'broadcast',
  ],
};

function Codes(props) {
  const {
    client,
    codesPageState,
    updatePageState,
  } = props;
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(initColumnVisibilityModel);
  const [snackMessage, setSnackMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackWithConfirm, setSnackWithConfirm] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [bulkEditOpen, setBulkEditOpen] = useState(false);
  // tracks data in create/edit modal
  const [editRowData, setEditRowData] = useState({});
  const [bulkEditFormData, setBulkEditFormData] = useState({});
  // codes modified for preview/bulk edit and to send to batchEditCodes mutation
  const [codesToUpdate, setCodesToUpdate] = useState([]);
  const [previewCodesAll, setPreviewCodesAll] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { authenticated, setShouldLogOut } = useContext(AppContext);
  const [sourceSearch, setSourceSearch] = useState('');
  const [sourceOptions, setSourceOptions] = useState([]);
  const [searching, setSearching] = useState(false);

  let oneYearAgo = new Date(new Date().setDate(new Date().getDate() - 365)); // -365 days from now
  oneYearAgo = new Date(oneYearAgo.setHours(0, 0, 0, 0)); // set to midnight

  // when we have user input from the MPAutocomplete component - we want to run a query
  const searchForSources = async (subString) => {
    /**
     * searches Sources table for a direct or partial match to the substring passed in
     *
     * @param {String} subString the search that the user has typed into the field
     * @returns {Array} an array of sources from the sources table that match the criteria
     */
    const filter = {
      client_id: { eq: client },
    };
    const wordList = subString.split(/[^a-zA-Z0-9]+/).filter(Boolean); // split on non-alphanumeric characters, remove empty strings
    const searchString = wordList.join('*');
    filter.and = {
      or: [
        { source: { eq: `${subString}` } }, // search for full string
        {
          or: [
            { source: { wildcard: `*${searchString}*` } }, // search for combo of words without spaces/non-alphanumeric characters
            { source: { match: `${subString}` } }, // search for straight substring match
          ],
        },
      ],
    };
    setSearching(true);
    const results = await APIWrapper.queryApi(
      { query: 'searchSources' },
      setShouldLogOut,
      {
        filter,
        limit: 250,
      },
    ).catch((err) => { logger.error(err); });
    setSearching(false);
    return results.data[0];
  };

  useEffect(() => {
    const getSourcesForDropdown = async () => {
      if (authenticated && client) {
        try {
          const results = await searchForSources(sourceSearch);
          const sources = [];
          results.forEach((item) => sources.push(item.source));
          setSourceOptions(sources);
        } catch (err) {
          logger.error(err);
        }
      }
    };
    getSourcesForDropdown();
  }, [sourceSearch]);

  // onclick handler for edit icon in the table actions column
  const handleEditClick = (event, row) => {
    const curRow = row.row;
    if (!curRow.from_goop) {
      setEditRowData(curRow);
      setIsDialogOpen(true);
    } else {
      setSnackMessage('The source was created in Goop and so you need to update it in Goop.');
      setOpenSnackbar(true);
    }
  };

  // also clearing the form here in case the user exited the form by clicking off the form
  // in which case the values may still be populated bc form state was not cleared
  const handleBulkEditClick = () => {
    // clear existing values from form state
    const isPreview = false;
    const previewData = [];
    updatePageState({ ...codesPageState, isPreview, previewData });
    setBulkEditFormData({});
    setCodesToUpdate([]);

    setBulkEditOpen(true);
  };

  const getPreviewData = (curCodesData, selectedRows_, bulkEditFormData_) => {
    const selectedRowIds = selectedRows_.map((row) => row.id);
    const previewData = [];
    const codesToEdit = [];
    curCodesData.forEach((obj) => {
      if (selectedRowIds.indexOf(obj.id) > -1) {
        const updatedObj = { ...obj, ...bulkEditFormData_ };
        previewData.push(updatedObj);
        codesToEdit.push(updatedObj);
      } else {
        previewData.push(obj);
      }
    });
    setCodesToUpdate(codesToEdit);
    setPreviewCodesAll(previewData);
    return previewData;
  };

  // made preview into a state for the base table (instead of creating a separate preview component)
  const togglePreview = () => {
    const isPreview = true;
    const previewData = getPreviewData(codesPageState.data, selectedRows, bulkEditFormData);
    updatePageState({ ...codesPageState, isPreview, previewData });
    setBulkEditOpen(false);
    setSnackMessage('Do you wish to save the previewed changes?');
    setSnackWithConfirm(true);
    setOpenSnackbar(true);
  };

  // clear state for bulk edit form
  const handleBulkEditFormCancel = () => {
    const isPreview = false;
    const previewData = [];
    updatePageState({ ...codesPageState, isPreview, previewData });
    setBulkEditFormData({});
    setSelectedRows([]);
    setCodesToUpdate([]);
    setBulkEditOpen(false);
  };

  const actionColumns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      disableClickEventBubbling: true,
      cellClassName: 'actions',
      disableReorder: true,
      getActions: (row) => [
        <GridActionsCellItem
          icon={<EditIcon color={row.row.from_goop ? 'disabled' : ''} />}
          label="Edit"
          className="textPrimary"
          onClick={(ev) => handleEditClick(ev, row)}
          color="inherit"
          size="medium"
        />,
      ],
    },
  ];

  const columns = actionColumns.concat(codeColumns);

  // part of component state; I would rather have it above but columns has
  // dependencies on methods defined above
  const [orderedColumns, setOrderedColumns] = useState(columns);

  const saveFunc = (newRow) => {
    if (authenticated) {
      const copyNewRow = { ...newRow };
      copyNewRow.fixed_cost_cpa_override = newRow.fixed_cost_cpa_override
        ? parseFloat(newRow.fixed_cost_cpa_override) : null;
      copyNewRow.fixed_cost_media_spend = newRow.fixed_cost_media_spend ? parseFloat(
        newRow.fixed_cost_media_spend,
      ) : null;
      copyNewRow.most_recent_spend = newRow.most_recent_spend
        ? parseFloat(newRow.most_recent_spend) : null;

      // removing fields that aren't specified in input for the query/mutation
      const keysToDelete = [
        'client_id', 'code', 'most_recent_spend_date', 'most_recent_transaction_date', 'platforms_with_spend',
        'revenue', 'transactions', 'total_spend', 'updatedAt', 'createdAt', 'page', 'actblue_managing_entities',
      ];
      keysToDelete.forEach((key) => {
        delete copyNewRow[key];
      });

      APIWrapper.queryApi({ mutation: 'updateCodes' }, setShouldLogOut, {
        input: copyNewRow,
      })
        .then((resp) => {
          const results = (resp.data && resp.data[0] && resp.data[0][0]) ? resp.data[0][0] : null;
          if (results) {
            setSnackMessage(`${results.code} was updated.`);
            setOpenSnackbar(true);
          }
          if (resp.errors) {
            const errMsg = resp.errors[0].message
              || 'Changes to the row could not be saved.';
            setSnackMessage(errMsg);
            setOpenSnackbar(true);
          }
        })
        .catch((err) => {
          logger.error(err);
          const errMsg = err.errors[0].message || 'Changes to the row could not be saved.';
          setSnackMessage(errMsg);
          setOpenSnackbar(true);
        });
    }
  };

  // used for Snackbar (handles click Cancel and Close - X)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    // if in preview state
    // (user clicks X to cancel the update/preview)
    if (codesPageState.isPreview) {
      setSelectedRows([]);
      updatePageState({ ...codesPageState, isPreview: false, previewData: [] });
      setCodesToUpdate([]);
      setBulkEditFormData({});
    }

    setOpenSnackbar(false);
    setSnackMessage('');
    setSnackWithConfirm(false);
  };

  // for dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleBulkEditClose = () => {
    setBulkEditOpen(false);
  };

  const validateInput = (row) => {
    /**
     * takes a row and checks whether the source is set
     * @param {Object} row the row being edited
     * @returns {Boolean} true if source is set, false if source is null.
     */
    if (row.source !== null) {
      return true;
    }
    return false;
  };

  const handleModalSave = () => {
    /**
     * validates row being edited and triggers save if it is good
     */
    const validRow = validateInput(editRowData);
    if (!validRow) {
      setSnackMessage('Please select a Source to save these changes');
      setOpenSnackbar(true);
    } else if (validRow) {
      // update the row in the table manually
      const rowIdx = getRowIdxForId(editRowData.id, codesPageState.data);
      if (rowIdx > -1) {
        const copyRows = codesPageState.data.slice(0);
        copyRows.splice(rowIdx, 1, editRowData);

        updatePageState((old) => ({ ...old, data: copyRows }));
        saveFunc(editRowData);
        setIsDialogOpen(false);
      }
    }
  };

  /* responds to onChange event in order to track updates to the row from the
    create/edit modal/dialog; updates state in this component which will be
    used to send to the graphql mutation */
  const setter = (key, value) => {
    // this was workaround to save object in state reliably
    // eslint-disable-next-line no-shadow
    setEditRowData((editRowData) => ({ ...editRowData, [key]: value }));
  };

  const bulkEditsetter = (key, value) => {
    // eslint-disable-next-line no-shadow
    setBulkEditFormData((bulkEditFormData) => ({ ...bulkEditFormData, [key]: value }));
  };

  // removing fields that aren't specified in input for the query/mutation
  const getCodeWithoutUnmutableFields = (code) => {
    const copyCode = { ...code };
    const keysToDelete = ['createdAt', 'client_id', 'code', 'fixed_cost_cpa_override', 'fixed_cost_media_spend', 'from_goop',
      'most_recent_spend', 'most_recent_spend_date', 'most_recent_transaction_date', 'platforms_with_spend', 'revenue', 'transactions', 'total_spend', 'page', 'actblue_managing_entities'];
    keysToDelete.forEach((key) => { delete copyCode[key]; });
    return copyCode;
  };

  const getPreviewSavedSummary = (status) => {
    /**
     * returns a list of rejected or fulfilled codes depending on the status parameter passed in.
     * @param {String} status rejected or fulfilled status from the lambda response
     * @returns {String} a comma separated list of codes
     */
    const msg = codesToUpdate.reduce((accum, cur) => {
      if (status === 'rejected') {
        if (cur.source === null) {
          return `${accum} ${cur.code},`;
        }
      } else if (status === 'fulfilled') {
        if (cur.source !== null) {
          return `${accum} ${cur.code},`;
        }
      }
      return `${accum}`;
    }, '');
    return msg;
  };

  const bulkUpdateFunc = () => {
    /**
     * Triggered when user confirms the previewed changes in the snackbar message. Takes the
     * list of codes to update, and sends them to the batchEditCodes lambda function for processing
     */
    if (authenticated) {
      const cleanedCodesToUpdate = codesToUpdate.map((code) => getCodeWithoutUnmutableFields(code));
      const inputObj = { requests: cleanedCodesToUpdate };
      const input = JSON.stringify(inputObj);

      APIWrapper.queryApi(
        { query: 'batchEditCodes' },
        setShouldLogOut,
        { input },
      )
        .then((resp) => {
          // handle actual appsync errors
          if (resp.errors && resp.errors.length) {
            setSnackMessage('The previewed changes could not be saved. Please submit a tools help ticket so we can investigate the issue.');
            setOpenSnackbar(true);
            setSelectedRows([]);
            updatePageState({
              ...codesPageState, isPreview: false, previewData: [],
            });
            setCodesToUpdate([]);
            setBulkEditFormData({});
          } else {
            // handle information returned from the lambda/docclient failures and successes together
            const respData = JSON.parse(resp.data[0]);
            let fulfilledCount = 0;
            let rejectedCount = 0;
            const rejectedReasons = [];
            for (let i = 0; i < respData.length; i += 1) {
              const current = respData[i];
              if (current.status === 'fulfilled') {
                fulfilledCount += 1;
              } else if (current.status === 'rejected') {
                rejectedCount += 1;
                rejectedReasons.push(current.reason.message);
              }
            }
            let message = '';
            if (rejectedCount > 0) {
              const rejList = getPreviewSavedSummary('rejected').slice(0, -1);
              message += `The following codes failed to update because they did not have a source defined:${rejList}. Please group a source with these codes to update!`;
            }
            if (fulfilledCount > 0) {
              const fulList = getPreviewSavedSummary('fulfilled').slice(0, -1);
              message += ` Successfully updated the following codes:${fulList}.`;
            }
            setSnackMessage(message);
            setOpenSnackbar(true);
            setSelectedRows([]);
            updatePageState({
              ...codesPageState, data: previewCodesAll, isPreview: false, previewData: [],
            });
            setCodesToUpdate([]);
            setBulkEditFormData({});
          }
        });
    }
  };

  const handleSnackBulkEditUpdateConfirm = () => {
    bulkUpdateFunc();
    setSnackWithConfirm(false);
    setOpenSnackbar(false);
    setSnackMessage('');
  };

  const handleSnackBulkEditUpdateBack = () => {
    if (codesPageState.isPreview) {
      updatePageState({ ...codesPageState, isPreview: false });
      setBulkEditOpen(true);
    }
    setSnackWithConfirm(false);
    setOpenSnackbar(false);
    setSnackMessage('');
  };

  const actionForBulkEditUpdate = (
    <>
      <Button color="secondary" size="small" onClick={handleSnackBulkEditUpdateConfirm}>
        Save
      </Button>
      <Button color="secondary" size="small" onClick={handleSnackBulkEditUpdateBack}>
        Back
      </Button>
      <Button color="secondary" size="small" onClick={handleClose}>
        Cancel
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const actionDefault = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  useEffect(() => {
    const codesColumnVisibility = getLocalStorageTablePref('codes', 'column-visibility');
    if (!codesColumnVisibility) {
      updateTablePreferenceLocalStorage('codes', 'column-visibility', initColumnVisibilityModel);
    } else {
      setColumnVisibilityModel(codesColumnVisibility);
    }
  }, []);

  useEffect(() => {
    const codesColumnsLocStore = getLocalStorageTablePref('codes', 'column');
    const isCodesColDefUpdated = !diffListOfObjects(codeColumns, codesColumnsLocStore);

    if (!codesColumnsLocStore || isCodesColDefUpdated) {
      updateTablePreferenceLocalStorage('codes', 'column', columns);
    } else {
      let elToUpdate = codesColumnsLocStore[0];
      elToUpdate = {
        ...elToUpdate,
        getActions: (row) => [
          <GridActionsCellItem
            icon={<EditIcon color={row.row.from_goop ? 'disabled' : ''} />}
            label="Edit"
            className="textPrimary"
            onClick={(ev) => handleEditClick(ev, row)}
            color="inherit"
            size="medium"
          />,
        ],
      };
      codesColumnsLocStore[0] = elToUpdate;
      setOrderedColumns(codesColumnsLocStore);
    }
  }, [codesPageState]);

  // niceley formatted query for our API
  const [advancedSearchQuery, setAdvancedSearchQuery] = useState({
    client_id: {
      eq: client,
    },
    and: {
      most_recent_transaction_date: {
        gte: oneYearAgo.toISOString(),
      },
      and: {
        or: [
          {
            source: {
              exists: 'false',
            },
          },
          {
            source: {
              eq: '',
            },
          },
        ],
      },
    },
  });

  // used for chips
  const [userCriteria, setUserCriteria] = useState([]);

  const defaultSearch = [
    {
      col: 'source',
      op: 'exists',
      criteria: 'false',
      children: [
        {
          col: 'source',
          op: 'eq',
          criteria: '',
          id: 0,
        },
      ],
      id: 1,
    },
    {
      col: 'most_recent_transaction_date',
      op: 'gte',
      criteria: oneYearAgo.toISOString(),
      children: [],
      id: 3,
    },
  ];

  // search criteria user has entered in the advanced search
  const [currUserSearch, setCurrUserSearch] = useState(defaultSearch);

  const deleteChip = (parentIndex) => {
    setCurrUserSearch((prevState) => {
      const newState = [...prevState];
      newState.splice(parentIndex, 1);
      return newState;
    });
  };

  const defaultSort = {
    field: 'createdAt',
    direction: 'desc',
  };

  const [advancedSearchSort, setAdvancedSearchSort] = useState(defaultSort);

  // default/locked criteria the user can't delete - search must be scoped to current client
  const lockedCriteria = [
    {
      col: 'client_id',
      op: 'eq',
      criteria: client,
    },
  ];

  const getDefaultColumns = () => codeColumns;
  const isRowSelectable = (params) => !params.row.from_goop;

  return (
    <>
      <div className="table-actions">
        <div className="batch-edit-btn-container">
          <Button
            variant="contained"
            onClick={handleBulkEditClick}
          >
            Bulk Edit
          </Button>
          <p>
            {selectedRows.length}
            {' '}
            selected
          </p>
        </div>
        <AdvancedSearchV2
          currUserSearch={currUserSearch}
          setCurrUserSearch={setCurrUserSearch}
          searchSetter={setAdvancedSearchQuery}
          sortSetter={setAdvancedSearchSort}
          lockedCriteria={lockedCriteria}
          defaultCriteria={defaultSearch}
          defaultSort={defaultSort}
          columns={codeColumns}
          lockedColumns={['client_id']}
          setUserChips={setUserCriteria}
        />
      </div>
      <AdvancedSearchChip
        columns={codeColumns}
        lockedCriteria={lockedCriteria}
        userCriteria={userCriteria}
        setUserCriteria={setUserCriteria}
        onDelete={(index) => { deleteChip(index); }}
      />
      <div style={{ flexGrow: 1 }}>
        {client && (
          <ServerTable
            pageState={codesPageState}
            updatePageState={updatePageState}
            columns={orderedColumns}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
            filters={advancedSearchQuery}
            sortModel={advancedSearchSort}
            queryName="searchCodes"
            tablePrefix="codes-adv"
            checkboxSelection
            setSelectedRows={setSelectedRows}
            createRows={createCodeRows}
            getDefaultColumns={getDefaultColumns}
            setOrderedColumns={() => {}} // ls/saving prefs is in flux, adding this to prevent errs
            isRowSelectable={isRowSelectable}
          />
        )}
      </div>

      <Dialog onClose={handleCloseDialog} open={isDialogOpen} maxWidth="sm" fullWidth>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}>
          <DialogTitle>Editing Code</DialogTitle>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseDialog}
            sx={{ pr: 2 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            flexGrow: 1,
            p: 3,
            m: 1,
            borderRadius: 1,
          }}
        >
          <ClientTextField
            setFunction={setter}
            data={editRowData.client_id}
            attribute="client_id"
            label="Client"
            isDisabled
          />
          <ClientTextField
            setFunction={setter}
            data={editRowData.code}
            attribute="code"
            label="Code"
            isDisabled
          />
          <div className="codes-source-search-container">
            <MPAutocomplete
              setFunction={setter}
              options={sourceOptions}
              label="Source*"
              editRowData={editRowData}
              field="source"
              setSourceSearch={setSourceSearch}
            />
            {searching && <Loading />}
          </div>
          <ClientTextField
            setFunction={setter}
            data={editRowData.audience}
            attribute="audience"
            label="Audience"
          />
          <ClientTextField
            setFunction={setter}
            data={editRowData.audience_category}
            attribute="audience_category"
            label="Audience Category"
          />
          <ClientTextField
            setFunction={setter}
            data={editRowData.revenue_category}
            attribute="revenue_category"
            label="Revenue Category"
          />
          <ClientDropDown
            setFunction={setter}
            data={editRowData.type}
            attribute="type"
            label="Revenue Type"
            structure={structure}
          />
          <ClientTextField
            setFunction={setter}
            data={editRowData.fixed_cost_cpa_override}
            attribute="fixed_cost_cpa_override"
            label="Fixed Cost CPA"
            type="number"
          />
          <ClientTextField
            setFunction={setter}
            data={editRowData.fixed_cost_media_spend}
            attribute="fixed_cost_media_spend"
            label="Fixed Cost Media Spend"
            type="number"

          />
          <ClientTextField
            setFunction={setter}
            data={editRowData.most_recent_spend}
            attribute="most_recent_spend"
            label="Most Recent Spend"
            type="number"
          />
          <Button
            className="datagrid-rightside-buttons"
            variant="contained"
            style={{
              justifyContent: 'center',
              marginTop: '20px',
              marginBottom: '15px',
            }}
            onClick={handleModalSave}
          >
            Save
          </Button>
        </Box>
      </Dialog>

      {selectedRows
      && (
      <Dialog onClose={handleBulkEditClose} open={bulkEditOpen} maxWidth="sm" fullWidth>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}>
          <DialogTitle>
            Bulk Editing
            {' '}
            {selectedRows.length}
            {' '}
            Codes
          </DialogTitle>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleBulkEditClose}
            sx={{ pr: 2 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            flexGrow: 1,
            p: 3,
            m: 1,
            borderRadius: 1,
          }}
        >
          <div className="bulk-edit-codes-list">
            <p>Codes:</p>
            <ul>
              {selectedRows.map((row) => <li key={row.code}>{row.code}</li>)}
            </ul>
          </div>
          <div className="codes-source-search-container">
            <MPAutocomplete
              setFunction={bulkEditsetter}
              options={sourceOptions}
              editRowData={(bulkEditFormData && bulkEditFormData.source) ? bulkEditFormData : {}}
              label="Source"
              field="source"
              setSourceSearch={setSourceSearch}
            />
            {searching && <Loading />}
          </div>
          <ClientTextField
            setFunction={bulkEditsetter}
            data={(bulkEditFormData && bulkEditFormData.audience) ? bulkEditFormData.audience : ''}
            attribute="audience"
            label="Audience"
          />
          <ClientTextField
            setFunction={bulkEditsetter}
            data={(bulkEditFormData && bulkEditFormData.audience_category) ? bulkEditFormData.audience_category : ''}
            attribute="audience_category"
            label="Audience Category"
          />
          <ClientTextField
            setFunction={bulkEditsetter}
            data={(bulkEditFormData && bulkEditFormData.revenue_category) ? bulkEditFormData.revenue_category : ''}
            attribute="revenue_category"
            label="Revenue Category"
          />
          <ClientDropDown
            setFunction={bulkEditsetter}
            data={(bulkEditFormData && bulkEditFormData.type) ? bulkEditFormData.type : ''}
            attribute="type"
            label="Revenue Type"
            structure={structure}
          />
          <Button
            className="datagrid-rightside-buttons"
            variant="contained"
            style={{
              marginTop: '20px',
              marginBottom: '15px',
            }}
            onClick={togglePreview}
          >
            Preview Changes
          </Button>
          <Button
            className="datagrid-rightside-buttons"
            variant="contained"
            style={{
              marginTop: '20px',
              marginBottom: '15px',
            }}
            onClick={handleBulkEditFormCancel}
          >
            Cancel
          </Button>
        </Box>
      </Dialog>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={codesPageState.isPreview ? null : 6000}
        onClose={handleClose}
        message={snackMessage}
        action={snackWithConfirm ? actionForBulkEditUpdate : actionDefault}
      />
    </>
  );
}

export default Codes;

Codes.propTypes = {
  client: PropTypes.string.isRequired,
  updatePageState: PropTypes.func.isRequired,
  codesPageState: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
